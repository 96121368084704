const prod = {
  API_BASE: process.env.REACT_APP_API_BASE,
  LOG_LEVEL: Number(process.env.LOG_LEVEL) || 4,
};

const dev = {
  API_BASE: "http://localhost:8090",
  LOG_LEVEL: 0,
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

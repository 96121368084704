import { useState, useEffect } from "react";
import { Label } from "../ui/label";
import AutogrowTextarea from "../ui/autogrowtextarea";

const TextArea = ({ itemkey, content, onChange, defaultValue }) => {
  const [value, setValue] = useState("");

  const handleValueChange = (e) => {
    if (onChange) {
      setValue(e.target.value);
      onChange(content.key, e.target.value);
    } else {
      setValue(defaultValue || "");
    }
  };

  useEffect(() => {
    setValue(defaultValue);
    if (onChange) onChange(content.key, defaultValue);
  }, [defaultValue]);

  return (
    <div className="mt-5 text-start">
      <Label className="font-semibold">{content.label}</Label>
      <AutogrowTextarea
        minHeight={160}
        value={value}
        onChange={handleValueChange}
      />
    </div>
  );
};

export default TextArea;

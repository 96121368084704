import { useEffect, useState } from "react";
import { Bell, Globe, Menu, Plus, Search, Trash, X } from "lucide-react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import useOrganization from "../hooks/useOrganization";
import {
  ORGANIZATIONS,
  ADD_USER,
  COLLECTIONS,
  REMOVE_USER,
  REMOVE_USER_ADMIN,
  COLLECTIONS_GET_USERS,
} from "../lib/keys";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
  DialogTitle,
  DialogHeader,
} from "./ui/dialog";
import { Checkbox } from "./ui/checkbox";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { toast } from "sonner";
import useCollection from "../hooks/useCollection";
import useAuth from "../hooks/useAuth";
import { CollectionPost, CollectionUpdate, CollectionUser } from "../lib/types";
import { queryClient } from "../lib/queryClient";

import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuItem,
  ContextMenuContent,
} from "./ui/context-menu";

const InviteUserFormSchema = z.object({
  email: z.string().email(),
  isOrgAdmin: z.boolean(),
  orgId: z.string(),
});

const CreateCollectionFormSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters" }),
  level: z.number().min(0).max(30),
});

const UpdateCollectionFormSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters" }),
  users: z.array(z.string()),
  subCollections: z.array(z.string()),
});

export default function Dashboard() {
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [collectionIdOpen, setCollectionIdOpen] = useState("");
  const [tab, setTab] = useState("users");
  const [isCreateCollectionDialogOpen, setIsCreateCollectionDialogOpen] =
    useState(false);
  const [showDeleteCollectionConfirm, setShowDeleteCollectionConfirm] =
    useState(false);

  const { auth: session } = useAuth();

  const {
    fetchOrganizations,
    addUser,
    deleteOrganization,
    removeUser,
    removeUserAdmin,
  } = useOrganization();

  const {
    fetchCollections,
    createCollection,
    deleteCollection,
    getCollectionUsers,
    updateCollection,
  } = useCollection();

  const form = useForm<z.infer<typeof InviteUserFormSchema>>({
    resolver: zodResolver(InviteUserFormSchema),
    defaultValues: {
      email: "",
      isOrgAdmin: false,
      orgId: "",
    },
  });

  const { data: organizations, isSuccess: isOrganizationsSuccess } = useQuery({
    queryKey: [ORGANIZATIONS],
    queryFn: fetchOrganizations,
    enabled: tab === "users",
  });

  const { data: collections } = useQuery({
    queryKey: [COLLECTIONS, form.watch("orgId")],
    queryFn: () => fetchCollections(form.watch("orgId")),
    enabled: tab === "collections" && !!form.watch("orgId"),
  });

  const { mutate: addUserMutation, isSuccess: isAddUserSuccess } = useMutation({
    mutationFn: addUser,
    mutationKey: [ORGANIZATIONS, ADD_USER],
  });

  const { mutate: removeUserMutation, isSuccess: isRemoveUserSuccess } =
    useMutation({
      mutationFn: ({ orgId, userId }: { orgId: string; userId: string }) =>
        removeUser(orgId, userId),
      mutationKey: [ORGANIZATIONS, REMOVE_USER],
    });

  const {
    mutate: removeUserAdminMutation,
    isSuccess: isRemoveUserAdminSuccess,
  } = useMutation({
    mutationFn: ({ orgId, userId }: { orgId: string; userId: string }) =>
      removeUserAdmin(orgId, userId),
    mutationKey: [ORGANIZATIONS, REMOVE_USER_ADMIN],
  });

  const { data: collectionUsers, isSuccess: isCollectionUsersSuccess } =
    useQuery({
      queryKey: [COLLECTIONS_GET_USERS, form.watch("orgId")],
      queryFn: () => getCollectionUsers(collectionIdOpen),
      enabled:
        tab === "collections" && !!form.watch("orgId") && !!collectionIdOpen,
    });

  useEffect(() => {
    if (isCollectionUsersSuccess) {
      updateCollectionForm.setValue(
        "users",
        collectionUsers?.fiUsers?.map((user: CollectionUser) => user._id)
      );
    }
  }, [isCollectionUsersSuccess]);

  useEffect(() => {
    if (collections?.find((c) => c._id === collectionIdOpen)) {
      updateCollectionForm.setValue(
        "name",
        collections?.find((c) => c._id === collectionIdOpen)?.name || ""
      );
      updateCollectionForm.setValue(
        "users",
        collections
          ?.find((c) => c._id === collectionIdOpen)
          ?.fiUsers.map((id: string) => id) || []
      );
    }
  }, [collectionIdOpen, collections]);

  const {
    mutate: updateCollectionMutation,
    isSuccess: isUpdateCollectionSuccess,
  } = useMutation({
    mutationFn: (data: CollectionUpdate) =>
      updateCollection(collectionIdOpen, data),
    mutationKey: [COLLECTIONS],
  });

  useEffect(() => {
    if (isUpdateCollectionSuccess) {
      toast.success("Collection updated successfully");
      setCollectionIdOpen("");
      queryClient.invalidateQueries({ queryKey: [COLLECTIONS] });
    }
  }, [isUpdateCollectionSuccess]);

  const {
    mutate: deleteCollectionMutation,
    isSuccess: isDeleteCollectionSuccess,
  } = useMutation({
    mutationFn: deleteCollection,
    mutationKey: [COLLECTIONS],
  });

  useEffect(() => {
    if (isDeleteCollectionSuccess) {
      toast.success("Collection deleted successfully");
      setCollectionIdOpen("");
      setShowDeleteCollectionConfirm(false);
      queryClient.invalidateQueries({ queryKey: [COLLECTIONS] });
    }
  }, [isDeleteCollectionSuccess]);

  useEffect(() => {
    if (isRemoveUserAdminSuccess) {
      toast.success("User removed as admin successfully");
      queryClient.invalidateQueries({ queryKey: [ORGANIZATIONS] });
    }
  }, [isRemoveUserAdminSuccess]);

  useEffect(() => {
    if (isRemoveUserSuccess) {
      toast.success("User removed successfully");
      queryClient.invalidateQueries({ queryKey: [ORGANIZATIONS] });
    }
  }, [isRemoveUserSuccess]);

  const {
    mutate: deleteOrganizationMutation,
    isSuccess: isDeleteOrganizationSuccess,
  } = useMutation({
    mutationFn: deleteOrganization,
    mutationKey: [ORGANIZATIONS],
  });

  useEffect(() => {
    if (isDeleteOrganizationSuccess) {
      toast.success("Organization deleted successfully");
      queryClient.invalidateQueries({ queryKey: [ORGANIZATIONS] });
    }
  }, [isDeleteOrganizationSuccess]);

  const {
    mutate: createCollectionMutation,
    isSuccess: isCreateCollectionSuccess,
  } = useMutation({
    mutationFn: (data: CollectionPost) =>
      createCollection(data, form.watch("orgId")),
    mutationKey: [COLLECTIONS],
  });

  const collectionForm = useForm<z.infer<typeof CreateCollectionFormSchema>>({
    resolver: zodResolver(CreateCollectionFormSchema),
    defaultValues: {
      name: "",
      level: 0,
    },
  });

  const updateCollectionForm = useForm<
    z.infer<typeof UpdateCollectionFormSchema>
  >({
    resolver: zodResolver(UpdateCollectionFormSchema),
    defaultValues: {
      name: "",
      users: [],
      subCollections: [],
    },
  });

  function onCreateCollectionSubmit(
    values: z.infer<typeof CreateCollectionFormSchema>
  ) {
    createCollectionMutation({
      name: values.name,
      level: values.level,
      users: [],
      subCollections: [],
    });
  }

  useEffect(() => {
    if (isCreateCollectionSuccess) {
      toast.success("Collection created successfully");
      collectionForm.reset();
      queryClient.invalidateQueries({ queryKey: [COLLECTIONS] });
      setIsCreateCollectionDialogOpen(false);
    }
  }, [isCreateCollectionSuccess]);

  useEffect(() => {
    if (isAddUserSuccess) {
      toast.success("User added successfully");
      setIsInviteDialogOpen(false);
      form.setValue("email", "");
      form.setValue("isOrgAdmin", false);
    }
  }, [isAddUserSuccess]);

  useEffect(() => {
    if (isOrganizationsSuccess && organizations?.[0]) {
      form.setValue("orgId", organizations?.[0]._id);
    }
  }, [isOrganizationsSuccess, organizations]);

  const { setValue: setFormValue } = form;

  function onInviteSubmit(values: z.infer<typeof InviteUserFormSchema>) {
    addUserMutation(values);
  }

  const onUpdateCollectionSubmit = (
    values: z.infer<typeof UpdateCollectionFormSchema>
  ) => {
    updateCollectionMutation({
      ...values,
    });
  };

  const engagements = [
    {
      id: "1",
      name: "Q4 Sales Strategy",
      participants: 4,
      lastActivity: "2024-09-01",
    },
    {
      id: "2",
      name: "Product Launch Plan",
      participants: 6,
      lastActivity: "2024-09-02",
    },
    {
      id: "3",
      name: "Code Review Process",
      participants: 3,
      lastActivity: "2024-08-31",
    },
  ];

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div className="flex flex-col flex-1 overflow-hidden">
        <aside
          className={`"-translate-x-full" inset-y-0 left-0 w-max-content bg-white border-r transform transition-transform duration-200 ease-in-out relative translate-x-0`}
        >
          <nav>
            <ToggleGroup
              type="single"
              value={form.watch("orgId")}
              onValueChange={(value) =>
                setFormValue("orgId", value || organizations?.[0]?._id!)
              }
              className="flex-col items-start p-3"
            >
              {organizations?.map((org) => (
                <ContextMenu>
                  <ContextMenuTrigger className="select-none md:select-auto">
                    <ToggleGroupItem
                      className="rounded-lg data-[state=on]:bg-primary data-[state=on]:text-white select-none md:select-auto"
                      key={org._id}
                      value={org._id}
                    >
                      {org.name}
                    </ToggleGroupItem>
                  </ContextMenuTrigger>
                  <ContextMenuContent>
                    <ContextMenuItem
                      className="text-red-500"
                      onClick={() => deleteOrganizationMutation(org._id)}
                    >
                      Delete <Trash className="ms-auto h-4 w-4" />
                    </ContextMenuItem>
                  </ContextMenuContent>
                </ContextMenu>
              ))}
            </ToggleGroup>
          </nav>
        </aside>

        {/* Main content */}
        <main className="flex-1 p-4 md:p-8 overflow-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold">Dashboard</h1>
          </div>
          <Tabs defaultValue="users" value={tab} onValueChange={setTab}>
            <TabsList className="mb-4">
              <TabsTrigger value="users">Users</TabsTrigger>
              <TabsTrigger value="collections">Collections</TabsTrigger>
              <TabsTrigger value="engagements">Engagements</TabsTrigger>
            </TabsList>
            <TabsContent value="users">
              <Card>
                <CardHeader>
                  <CardTitle>Users</CardTitle>
                  <CardDescription>
                    Manage users and their roles
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Email</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {organizations
                        ?.find((org) => org._id === form.watch("orgId"))
                        ?.fiUsers.filter(
                          (user) =>
                            user.isActive && user.email !== session?.email
                        )
                        .map((user, index) => (
                          <TableRow key={`${user._id}-${index}`}>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button variant="outline" size="sm">
                                    Manage
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <DropdownMenuItem
                                    className="text-red-500"
                                    onClick={() =>
                                      removeUserMutation({
                                        orgId: form.watch("orgId"),
                                        userId: user._id,
                                      })
                                    }
                                  >
                                    Remove
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    className="text-red-500"
                                    onClick={() =>
                                      removeUserAdminMutation({
                                        orgId: form.watch("orgId"),
                                        userId: user._id,
                                      })
                                    }
                                  >
                                    Remove as Admin
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Dialog
                    open={isInviteDialogOpen}
                    onOpenChange={setIsInviteDialogOpen}
                  >
                    <DialogTrigger asChild>
                      <Button
                        className="mt-4"
                        onClick={() => setIsInviteDialogOpen(true)}
                      >
                        <Plus className="mr-2 h-4 w-4" /> Invite User
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                        <DialogTitle className="sr-only">
                          Invite User
                        </DialogTitle>
                      </DialogHeader>
                      <Form {...form}>
                        <form onSubmit={form.handleSubmit(onInviteSubmit)}>
                          <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="hello@example.com"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="isOrgAdmin"
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-start gap-3 space-y-0 rounded-md border border-primary p-4 my-4">
                                <FormControl>
                                  <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                                <FormLabel>
                                  Invite as Organization Admin
                                </FormLabel>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <DialogFooter>
                            <Button type="submit">Send Invitation</Button>
                          </DialogFooter>
                        </form>
                      </Form>
                    </DialogContent>
                  </Dialog>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="collections">
              <Card>
                <CardHeader>
                  <CardTitle>Collections</CardTitle>
                  <CardDescription>
                    Manage organizational structure
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Level</TableHead>
                        <TableHead>Users</TableHead>
                        <TableHead>Subcollections</TableHead>
                        <TableHead>Parent Collections</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {collections?.map((collection) => (
                        <TableRow key={collection._id}>
                          <TableCell>{collection.name}</TableCell>
                          <TableCell>{collection.level}</TableCell>
                          <TableCell>{collection.fiUsers.length}</TableCell>
                          <TableCell>
                            {collection.subCollections.length}
                          </TableCell>
                          <TableCell>
                            {collection.parentCollections.length}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                setCollectionIdOpen(collection._id);
                              }}
                            >
                              Manage
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Dialog
                    open={isCreateCollectionDialogOpen}
                    onOpenChange={setIsCreateCollectionDialogOpen}
                  >
                    <DialogTrigger asChild>
                      <Button className="mt-4">
                        <Plus className="mr-2 h-4 w-4" /> Create Collection
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                        <DialogTitle>Create Collection</DialogTitle>
                      </DialogHeader>
                      <Form {...collectionForm}>
                        <form
                          onSubmit={collectionForm.handleSubmit(
                            onCreateCollectionSubmit
                          )}
                          className="space-y-4"
                        >
                          <FormField
                            control={collectionForm.control}
                            name="name"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="Collection name"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={collectionForm.control}
                            name="level"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Level</FormLabel>
                                <FormControl>
                                  <Input
                                    type="number"
                                    min={0}
                                    max={30}
                                    {...field}
                                    onChange={(e) =>
                                      field.onChange(Number(e.target.value))
                                    }
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <DialogFooter>
                            <Button type="submit">Create Collection</Button>
                          </DialogFooter>
                        </form>
                      </Form>
                    </DialogContent>
                  </Dialog>
                  <Dialog
                    open={!!collectionIdOpen}
                    onOpenChange={() => setCollectionIdOpen("")}
                  >
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Update Collection</DialogTitle>
                      </DialogHeader>
                      <Form {...updateCollectionForm}>
                        <form
                          onSubmit={updateCollectionForm.handleSubmit(
                            onUpdateCollectionSubmit
                          )}
                          className="space-y-4"
                        >
                          <FormField
                            control={updateCollectionForm.control}
                            name="name"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="Collection name"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={updateCollectionForm.control}
                            name="users"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Users</FormLabel>
                                <FormControl>
                                  <ToggleGroup
                                    type="multiple"
                                    value={field.value}
                                    onValueChange={field.onChange}
                                  >
                                    {organizations
                                      ?.find(
                                        (org) => org._id === form.watch("orgId")
                                      )
                                      ?.fiUsers.map((user) => (
                                        <ToggleGroupItem
                                          key={user._id}
                                          value={user._id}
                                          aria-label={user.email}
                                          className={
                                            collectionUsers?.fiUsers?.some(
                                              (
                                                collectionUser: CollectionUser
                                              ) =>
                                                collectionUser._id === user._id
                                            )
                                              ? "bg-primary/20"
                                              : ""
                                          }
                                        >
                                          {user.email}
                                        </ToggleGroupItem>
                                      ))}
                                  </ToggleGroup>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <DialogFooter>
                            <div className="flex justify-between w-full items-center">
                              <Button
                                type="button"
                                size="sm"
                                variant="destructive"
                                onClick={() =>
                                  setShowDeleteCollectionConfirm(true)
                                }
                              >
                                <Trash className="h-4 w-4" />
                              </Button>
                              <div className="flex gap-2">
                                <Button type="submit">Update Collection</Button>
                              </div>
                            </div>
                          </DialogFooter>
                        </form>
                      </Form>
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    open={showDeleteCollectionConfirm}
                    onOpenChange={setShowDeleteCollectionConfirm}
                  >
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Delete Collection</DialogTitle>
                      </DialogHeader>
                      <p>
                        Are you sure you want to delete this collection? This
                        action cannot be undone.
                      </p>
                      <DialogFooter>
                        <Button
                          variant="outline"
                          onClick={() => setShowDeleteCollectionConfirm(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="destructive"
                          onClick={() => {
                            deleteCollectionMutation(collectionIdOpen);
                          }}
                        >
                          Delete
                        </Button>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="engagements">
              <Card>
                <CardHeader>
                  <CardTitle>Engagements</CardTitle>
                  <CardDescription>
                    Manage and collaborate on engagements
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Participants</TableHead>
                        <TableHead>Last Activity</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {engagements?.map((engagement) => (
                        <TableRow key={engagement.id}>
                          <TableCell>{engagement.name}</TableCell>
                          <TableCell>{engagement.participants}</TableCell>
                          <TableCell>{engagement.lastActivity}</TableCell>
                          <TableCell>
                            <Button variant="outline" size="sm">
                              Collaborate
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button className="mt-4">
                    <Plus className="mr-2 h-4 w-4" /> Create Engagement
                  </Button>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </main>
      </div>
    </div>
  );
}

import useRetryFetch from "./useRetryFetch";
import { config } from "../Environment";
import endpoints from "../lib/endpoints";
import { Organization } from "../lib/types";

const useOrganization = () => {
  const api = useRetryFetch();

  const fetchOrganizations = async () => {
    const response = await api(`${config.API_BASE}${endpoints.organizations}`);
    return (await response.json()) as Organization[];
  };

  const addUser = async (data: {
    orgId: string;
    email: string;
    isOrgAdmin: boolean;
  }) => {
    const { orgId, email, isOrgAdmin } = data;

    const response = await api(
      `${config.API_BASE}${endpoints.organizationsAddUser}/${orgId}/${email}`,
      {
        method: "PUT",
        body: JSON.stringify({ asAdmin: isOrgAdmin }),
      }
    );
    return await response.json();
  };

  const deleteOrganization = async (orgId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.organizations}/${orgId}`,
      {
        method: "DELETE",
      }
    );
    return await response.json();
  };

  const removeUser = async (orgId: string, userId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.organizationsRemoveUser}/${orgId}/${userId}`,
      {
        method: "PUT",
      }
    );
    return await response.json();
  };

  const removeUserAdmin = async (orgId: string, userId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.organizationsRemoveUserAdmin}/${orgId}/${userId}`,
      {
        method: "PUT",
      }
    );
    return await response.json();
  };

  return {
    fetchOrganizations,
    addUser,
    deleteOrganization,
    removeUser,
    removeUserAdmin,
  };
};

export default useOrganization;

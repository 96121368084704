import Item from "./Item";
import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect, useMemo } from "react";
import { Plus, Braces } from "lucide-react";
import ButtonTooltip from "./ButtonToolTip";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";

import "./List.css";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
} from "@radix-ui/react-dropdown-menu";

import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

function List({
  list,
  items,
  listData,
  setListData,
  setShowItemEdit,
  setItemIdToEdit,
  setTempOptions,
  setTempItems,
  saveItemContent,
  saveOptionItemContent,
  saveItemItemContent,
  autofocusindex,
  tempFlowContext,
  setTempFlowContext,
}) {
  const { t } = useTranslation();

  const [cursorPos, setCursorPos] = useState(-1);
  const [variableInUse, setVariableInUse] = useState(false);
  const [listDataIntent, setListDataIntent] = useState({});
  const [tempFlowContextIntent, setTempFlowContextIntent] = useState([]);

  const contentRefs = useRef({});
  const insertRef = useRef(null);

  const selectableVariables = useMemo(() => {
    return tempFlowContext
      .sort((a, b) => {
        if (a.description < b.description) return -1;
        if (a.description > b.description) return 1;
        return 0;
      })
      .filter(
        (variable) => !listData.lists[list.id].itemIds.includes(variable.key) // not on current dialog
      );
  }, [tempFlowContext, listData]);

  const removeItemFromList = (itemId) => {
    // TODO first create potential next states for listData and tempFlowContext, but only delete now if entry is not used
    let key = listData.items[itemId].content.key;

    let newListData = structuredClone(listData);

    delete newListData.items[itemId];

    let idx = newListData.lists[list.id].itemIds.findIndex(
      (itemid) => itemid === itemId
    );

    newListData.lists[list.id].itemIds.splice(idx, 1);

    let used = false;
    let newTempFlowContext = structuredClone(tempFlowContext);
    if (key) {
      let entryIdx = newTempFlowContext.findIndex((entry) => entry.key === key);
      let entry = newTempFlowContext[entryIdx];
      entry.count = entry.count - 1;
      if (entry.count === 0) {
        newTempFlowContext.splice(entryIdx, 1);
        if (entry.used) {
          used = true;
        }
      }
    }

    if (used) {
      setListDataIntent(newListData);
      setTempFlowContextIntent(newTempFlowContext);
      setVariableInUse(true);
    } else {
      setListData(newListData);
      setTempFlowContext(newTempFlowContext);
    }
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const sel = document.getSelection();
      if (
        sel.anchorNode &&
        sel.anchorNode.parentNode.contentEditable === "true"
      ) {
        setCursorPos(sel.anchorOffset);
        insertRef.current = sel.anchorNode;
      }
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    // Vergessen Sie nicht, den EventListener zu entfernen, wenn die Komponente unmountet wird
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);

  const insertText = (flowContextKey) => {
    if (insertRef.current && cursorPos > -1) {
      // insertRef is a text node inside the contenteditable
      // it will be split in three new nodes
      // 1. text node before cursor (if cursorPos>0)
      // 2. span element
      // 3. text node after cursor (if cursorPos<textContent.length-1)

      let newLeftNode;
      let newRightNode;

      if (cursorPos > 0) {
        newLeftNode = document.createTextNode(
          insertRef.current.textContent.substring(0, cursorPos)
        );
      }
      if (cursorPos < insertRef.current.textContent.length - 1) {
        newRightNode = document.createTextNode(
          insertRef.current.textContent.substring(cursorPos)
        );
      }

      let newSpan = document.createElement("span");
      newSpan.contentEditable = false;
      newSpan.textContent = tempFlowContext.find(
        (entry) => entry.key === flowContextKey
      ).description;

      let parent = insertRef.current.parentNode;
      if (newLeftNode) parent.insertBefore(newLeftNode, insertRef.current);
      parent.insertBefore(newSpan, insertRef.current);
      if (newRightNode) parent.insertBefore(newRightNode, insertRef.current);
      parent.removeChild(insertRef.current);

      // Save new values in items
      setNewValues();

      cleanup();
    }
  };

  const getNewValues = () => {
    let result = {};
    for (let contentElKey of Object.keys(contentRefs.current)) {
      let contentEl = contentRefs.current[contentElKey];
      if (contentEl) {
        let localHTML = contentEl.innerHTML;
        let matches = localHTML.match(/<span.*?<\/span>/g);

        if (matches) {
          for (let match of matches) {
            let variableDesc = match.match(/>(.*)</)[1];
            localHTML = localHTML.replace(
              match,
              `{${
                tempFlowContext.find(
                  (variable) => variable.description === variableDesc
                ).key
              }}`
            );
          }
          localHTML = localHTML.replace(/&nbsp;/g, "");
        }

        result[contentElKey] = localHTML;
      }
    }
    return result;
  };

  const cleanup = () => {
    for (let contentElKey of Object.keys(contentRefs.current)) {
      let contentEl = contentRefs.current[contentElKey];
      let currentHTML = contentEl.innerHTML;

      let changed = false;

      if (currentHTML) {
        while (currentHTML.includes("><")) {
          // insert space between two tags
          let idx = currentHTML.indexOf("><");
          currentHTML =
            currentHTML.substring(0, idx + 1) +
            "&nbsp;" +
            currentHTML.substring(idx + 1);
          changed = true;
        }

        // add trailing space if HTML ends on a tag
        if (currentHTML.substring(currentHTML.length - 1) === ">") {
          currentHTML = currentHTML + "&nbsp;";
          changed = true;
        }

        // add leading space if HTML begins with a tag
        if (currentHTML.substring(0, 1) === "<") {
          currentHTML = "&nbsp;" + currentHTML;
          changed = true;
        }

        // add space if element is empty
        if (!currentHTML.length || currentHTML === "<br>") {
          currentHTML = "&nbsp;";
          changed = true;
        }

        if (currentHTML.includes("<br>")) {
          currentHTML = currentHTML.replace(/<br>/g, "");
          changed = true;
        }

        if (changed) contentEl.innerHTML = currentHTML;
      }
    }
    if (insertRef) {
      insertRef.current = null;
    }
    setCursorPos(-1);
  };

  const setNewValues = () => {
    let newValues = getNewValues();
    for (let fieldKey of Object.keys(newValues)) {
      let fieldIdx = listData.lists[list.id].itemIds.findIndex(
        (itemid) => itemid === fieldKey
      );
      saveItemContent(fieldIdx, newValues[fieldKey]);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        distance: 8,
        pressThreshold: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setListData((prevListData) => {
        const oldIndex = prevListData.lists[list.id].itemIds.findIndex(
          (id) => id === active.id
        );
        const newIndex = prevListData.lists[list.id].itemIds.findIndex(
          (id) => id === over.id
        );

        const newItemIds = arrayMove(
          prevListData.lists[list.id].itemIds,
          oldIndex,
          newIndex
        );

        return {
          ...prevListData,
          lists: {
            ...prevListData.lists,
            [list.id]: {
              ...prevListData.lists[list.id],
              itemIds: newItemIds,
            },
          },
        };
      });
    }
  };

  return (
    <div className="flex-1">
      <h2 className="maintitle">{list.title}</h2>
      <br />
      {!!items.find((item) => item.type === "interpolation") &&
      cursorPos !== -1 &&
      selectableVariables.length > 0 ? (
        <DropdownMenu>
          <DropdownMenuTrigger className="bg-transparent ms-2">
            <ButtonTooltip
              renderItem={() => (
                <Button style={{ width: "70px", height: "25px" }}>
                  <Plus />
                  <Braces />
                </Button>
              )}
              text={t("add_field_reference")}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white p-2 rounded border-2 border-black shadow-2xl">
            {selectableVariables.map((variable, idx) => (
              <DropdownMenuItem
                className="p-1 cursor-pointer hover:bg-gray-200"
                key={idx}
                onClick={() => insertText(selectableVariables[idx].key)}
              >
                <span>{variable.description}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
          {/* <DropdownMenuContent>
            {availableLanguages.map((lang, idx) => (
              <DropdownMenuItem
                key={idx}
                onClick={() => selectLanguage(lang.code)}
              >
                <span
                  className={`flag-icon mx-2 flag-icon-${lang.country_code}`}
                />
                {lang.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent> */}
        </DropdownMenu>
      ) : (
        <Button disabled style={{ width: "70px", height: "25px" }}>
          <Plus />
          <Braces />
        </Button>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={items.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="rounded-sm min-h-8 h-full w-full">
            {items.map((item, index) => (
              <Item
                key={item.id}
                item={item}
                index={index}
                removeItemFromList={() => removeItemFromList(item.id)}
                setShowItemEdit={setShowItemEdit}
                setItemIdToEdit={setItemIdToEdit}
                setTempOptions={setTempOptions}
                setTempItems={setTempItems}
                saveItemContent={saveItemContent}
                saveOptionItemContent={saveOptionItemContent}
                saveItemItemContent={saveItemItemContent}
                autofocus={index === autofocusindex}
                contentRefs={contentRefs}
                tempFlowContext={tempFlowContext}
                onUpdate={setNewValues}
                onCleanup={cleanup}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <Dialog open={variableInUse} onOpenChange={setVariableInUse}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("field_referenced_headline")}</DialogTitle>
          </DialogHeader>
          <p>{t("field_referenced_paragraph")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setVariableInUse(false);
                setListData(listDataIntent);
                setTempFlowContext(tempFlowContextIntent);
                setListDataIntent({});
                setTempFlowContextIntent([]);
              }}
              data-id={"delete"}
              key={0}
              variant={`outline`}
            >
              {t("delete")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setVariableInUse(false);
              }}
              data-id={"cancel"}
              key={0}
              variant={`default`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* <pre>{JSON.stringify(tempFlowContext, null, 2)}</pre> */}
    </div>
  );
}

export default List;

import { useState, useEffect } from "react";
import { Label } from "../ui/label";

const ActivitySlider = ({ itemkey, content, onChange, defaultValue }) => {
  const [value, setValue] = useState("0");

  const handleSliderValueChange = (e) => {
    if (onChange) {
      setValue(e.target.value);
      onChange(content.key, e.target.value);
    } else {
      setValue(defaultValue || "");
    }
  };

  useEffect(() => {
    setValue(defaultValue);
    if (onChange) onChange(content.key, defaultValue);
  }, [defaultValue]);

  return (
    <div className="mt-5 text-start">
      <Label className="font-semibold block mb-2">{content.label}</Label>
      <div className="flex items-center">
        <input
          type="range"
          className="w-[400px]"
          value={value}
          onChange={handleSliderValueChange}
          min={content.min}
          max={content.max}
          step={content.step}
        />
        <Label className="font-semibold ms-2">
          {!!onChange ? value : defaultValue}
        </Label>
      </div>
    </div>
  );
};

export default ActivitySlider;

import useRetryFetch from "./useRetryFetch";
import { config } from "../Environment";
import endpoints from "../lib/endpoints";
import { Collection, CollectionPost, CollectionUpdate } from "../lib/types";

const useCollection = () => {
  const api = useRetryFetch();

  const fetchCollections = async (orgId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.collections}/${orgId}`
    );
    return (await response.json()) as Collection[];
  };

  const createCollection = async (data: CollectionPost, orgId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.collections}/${orgId}`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  };

  const getCollectionUsers = async (collectionId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.collectionsGetUsers}/${collectionId}`
    );
    return await response.json();
  };

  const updateCollection = async (
    collectionId: string,
    data: CollectionUpdate
  ) => {
    const response = await api(
      `${config.API_BASE}${endpoints.collections}/${collectionId}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
      }
    );
  };

  const deleteCollection = async (collectionId: string) => {
    const response = await api(
      `${config.API_BASE}${endpoints.collections}/${collectionId}`,
      { method: "DELETE" }
    );
  };

  return {
    fetchCollections,
    createCollection,
    getCollectionUsers,
    updateCollection,
    deleteCollection,
  };
};

export default useCollection;

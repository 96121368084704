import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./Interpolation.css";

const Interpolation = ({
  value,
  flowcontext,
  contentRefs,
  itemId,
  onUpdate,
  autofocus,
}) => {
  const { t } = useTranslation();

  const convertValueToHTML = useCallback(() => {
    let localText = value === "<br>" ? "&nbsp;" : value;

    let matches = localText.match(/\{.*?\}/g);

    if (matches) {
      for (let match of matches) {
        let variableKey = match.match(/\{(.*)\}/)[1];
        let foundVariable = flowcontext.find(
          (variable) => variable.key === variableKey
        );
        if (foundVariable) {
          localText = localText.replace(
            match,
            `&nbsp;<span contenteditable="false">${foundVariable.description}</span>&nbsp;`
          );
        } else {
          localText = localText.replace(
            match,
            `&nbsp;<span contenteditable="false" class="unknown">${t(
              "unknown_reference"
            )}</span>&nbsp;`
          );
        }
      }
    }

    return localText;
  }, []);

  const handleBlur = (e) => {
    onUpdate();
  };

  useEffect(() => {
    if (contentRefs.current[itemId]) {
      if (autofocus) {
        contentRefs.current[itemId].focus();
        document.execCommand("selectAll", false, null);
      }
    }
  }, [contentRefs.current]);

  const preventBracketInput = (e) => {
    if (e.key === "{" || e.key === "}") e.preventDefault();
    e.stopPropagation();
  };

  const preventBracketPaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    if (paste.includes("{") || paste.includes("}")) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div
        className={`spiced`}
        contentEditable="true"
        onBlur={handleBlur}
        onKeyDown={preventBracketInput}
        onPaste={preventBracketPaste}
        ref={(el) => (contentRefs.current[itemId] = el)}
        dangerouslySetInnerHTML={{ __html: convertValueToHTML() }}
      ></div>
    </>
  );
};

export default Interpolation;

import { useState, useContext, useMemo } from "react";
import LoggerContext from "../context/LoggerProvider";
import "./MessageBox.css";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Input } from "./ui/input";

function MessageBox({ onButton, buttons, text, inputs, onClose, id, moreJSX }) {
  const { Logger } = useContext(LoggerContext);

  const getDefaultValues = () => {
    let result = null;
    for (let input of inputs) {
      if (input.defaultValue) {
        if (!result) result = {};
        result[input.id] = input.defaultValue;
      } else if (input.type === "select") {
        // for select always set the first option
        if (!result) result = {};
        result[input.id] = input.options[0]?.value;
      }
    }
    return result;
  };

  const dialogValid = useMemo(() => {
    let allInvalid = true;
    for (let input of inputs) {
      if (!input.invalid) {
        allInvalid = false;
      }
    }
    return !allInvalid;
  }, [inputs]);

  const [formData, setFormData] = useState(getDefaultValues());

  const handleButton = (e) => {
    let btnId = e.target.dataset.id;
    let result = {
      btnId,
      msgBoxId: id,
      data: formData,
    };
    onButton(result);
    onClose();
  };

  const renderInput = (input, index) => {
    let type = input.type || "text";

    const changeFormData = (event) => {
      const value = typeof event === "string" ? event : event.target.value;
      Logger.debug("Change of select detected", value);
      setFormData((prevFormData) => {
        return { ...prevFormData, [input.id]: value };
      });
    };

    switch (type) {
      case "text":
        return (
          <div key={index}>
            <h5 className="mt-1.5 mb-1">{input.text}</h5>
            <Input
              type="text"
              id={input.id}
              onChange={changeFormData}
              value={formData ? formData[input.id] : ""}
            />
          </div>
        );
      case "select":
        return (
          <>
            {input.options.length ? (
              <Select
                key={index}
                id={input.id}
                onValueChange={changeFormData}
                defaultValue={input.options[0].value}
              >
                <SelectTrigger className="max-w-[250px]">
                  <SelectValue placeholder={input.options[0].value} />
                </SelectTrigger>
                <SelectContent>
                  {input.options.map((option) => (
                    <SelectItem value={option.value}>{option.label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <div>Currently there are no selectable options</div>
            )}
          </>
        );
    }
  };

  return (
    <Dialog open={true} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{text}</DialogTitle>
          <div className="mb-2.5">{inputs.map(renderInput)}</div>
        </DialogHeader>
        {moreJSX}
        <div className="flex flex-row gap-2">
          {buttons.map((button, index) => (
            <>
              {!button.disabled && (dialogValid || !button.disableOnInvalid) ? (
                <Button
                  className="inline-block"
                  onClick={handleButton}
                  data-id={button.id}
                  key={index}
                  variant={`${index % 2 === 1 ? "outline" : "default"}`}
                >
                  {button.text}
                </Button>
              ) : (
                <></>
              )}
            </>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MessageBox;

import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: any) {
  return twMerge(clsx(inputs));
}

export const LANGUAGES = {
  en: { label: "english", dir: "ltr" },
  de: { label: "german", dir: "ltr" },
  he: { label: "hebrew", dir: "rtl" },
};

export const convertOldCustomToNew = (oldCustomData: any) => {
  let result = [];
  if (oldCustomData["richText"]) {
    let newid = "richtext-" + Math.floor(Math.random() * 10000);
    result.push({
      id: newid,
      type: "richtext",
      content: oldCustomData["richText"],
    });
  }

  if (oldCustomData["checkboxes"] && oldCustomData["checkboxes"].length) {
    let newid = "checkbox-" + Math.floor(Math.random() * 10000);
    result.push({
      id: newid,
      type: "checkbox",
      content: {
        label: "",
        key: newid,
        options: oldCustomData["checkboxes"].map((option: any) => {
          return {
            label: option.label,
            key: option.id,
          };
        }),
      },
    });
  }

  if (oldCustomData["twoDfeedback"]) {
    let newid = "gauge-" + Math.floor(Math.random() * 10000);
    result.push({
      id: newid,
      type: "gauge",
      content: { key: newid },
    });
  }

  return result;
};

import React, { createContext } from "react";
import { config } from "../Environment";

// Define LOG_LEVELS outside the component
const LOG_LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  NONE: 4,
} as const;

type LoggerType = {
  debug: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
};

// Create Logger factory function
const createLogger = (loglevel: number): LoggerType => ({
  debug:
    loglevel === LOG_LEVELS.DEBUG
      ? (...args: any[]) =>
          console.log(new Date().toISOString() + ": [DEBUG]", ...args)
      : () => {},
  info:
    loglevel <= LOG_LEVELS.INFO
      ? (...args: any[]) =>
          console.log(new Date().toISOString() + ": [INFO]", ...args)
      : () => {},
  warn:
    loglevel <= LOG_LEVELS.WARN
      ? (...args: any[]) =>
          console.log(new Date().toISOString() + ": [WARN]", ...args)
      : () => {},
  error:
    loglevel <= LOG_LEVELS.ERROR
      ? (...args: any[]) =>
          console.error(new Date().toISOString() + ": [ERROR]", ...args)
      : () => {},
});

// Define context type
type LoggerContextType = {
  loglevel: number;
  Logger: LoggerType;
  LOG_LEVELS: typeof LOG_LEVELS;
};

const LoggerContext = createContext<LoggerContextType>({
  loglevel: LOG_LEVELS.NONE,
  Logger: createLogger(LOG_LEVELS.NONE),
  LOG_LEVELS: LOG_LEVELS,
});

type LoggerProviderProps = {
  children: React.ReactNode;
};

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ children }) => {
  const loglevel = config.LOG_LEVEL;
  const Logger = createLogger(loglevel);

  return (
    <LoggerContext.Provider value={{ loglevel, Logger, LOG_LEVELS }}>
      {children}
    </LoggerContext.Provider>
  );
};

export { LOG_LEVELS, createLogger };
export type { LoggerType };
export default LoggerContext;

import Textbox from "./activityComponents/Textbox";
import TextArea from "./activityComponents/TextArea";
import Checkboxes from "./activityComponents/Checkboxes";
import Gauge from "./activityComponents/Gauge";
import Timer from "./activityComponents/Timer";
import ActivitySlider from "./activityComponents/ActivitySlider";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { convertOldCustomToNew, LANGUAGES } from "../lib/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./RenderReviewDialog.css";

const RenderReviewDialog = ({ logEntry, highlightedOption }) => {
  const renderItem = (item) => {
    switch (item.type) {
      // informative elements
      case "interpolation":
        return (
          <div
            className="mt-4 text-start"
            dangerouslySetInnerHTML={{ __html: interpolate(item.content) }}
          ></div>
        );
      case "richtext":
      case "richtextnew":
        return (
          <div
            className="ProseMirror mt-4 text-start"
            dangerouslySetInnerHTML={{ __html: item.content }}
          ></div>
        );
      case "hr":
        return <hr />;
      case "headline1":
        return <h2 className="text-2xl text-bold">{item.content}</h2>;
      case "headline2":
        return (
          <h3 className="text-lg my-1.5 leading-tight text-start">
            {item.content}
          </h3>
        );
      case "paragraph":
        return <p>{item.content}</p>;
      case "orderedlist":
        return (
          <div className="optioncomponent ptop text-start">
            <Label className="font-semibold">{item.content.label}</Label>
            <ol style={{ listStyleType: "decimal", marginLeft: "16px" }}>
              {item.content.items.map((olitem) => (
                <li key={olitem.key}>{olitem.label}</li>
              ))}
            </ol>
          </div>
        );
      // interactive elements
      case "textbox":
        return (
          <Textbox
            itemkey={item.id}
            content={item.content}
            defaultValue={logEntry?.userData[item.content.key]}
          />
        );
      case "textarea":
        return (
          <TextArea
            itemkey={item.id}
            content={item.content}
            defaultValue={logEntry?.userData[item.content.key]}
          />
        );
      case "checkbox":
        return (
          <Checkboxes
            itemkey={item.id}
            content={item.content}
            defaultValue={logEntry?.userData[item.content.key]}
          />
        );
      case "gauge":
        return (
          <Gauge
            itemkey={item.id}
            content={item.content}
            defaultValue={logEntry?.userData[item.content.key]}
          />
        );
      case "timer":
        return (
          <Timer
            itemkey={item.id}
            content={item.content}
            defaultValue={logEntry?.userData[item.content.key]}
          />
        );
      case "slider":
        return (
          <ActivitySlider
            itemkey={item.id}
            content={item.content}
            defaultValue={logEntry?.userData[item.content.key]}
          />
        );
      default:
    }
  };

  const interpolate = (content) => {
    let result = content;

    let userData = logEntry?.userData;

    if (userData) {
      let matches = result.match(/\{.*?\}/g);

      for (let match of matches) {
        let variableName = match.match(/\{(.*)\}/)[1];
        let transformed = structuredClone(userData[variableName]);
        if (typeof transformed !== "string") {
          transformed = JSON.stringify(transformed);
        }
        let newPart = "<b>" + transformed + "</b>";
        result = result.replace(match, newPart);
      }
    }
    return result;
  };

  const renderActivityComponents = () => {
    let components = logEntry?.activityDialog.custom;

    if (components && !Array.isArray(components)) {
      components = convertOldCustomToNew(components);
    }

    if (components) {
      return (
        <>
          {components.map((item) => (
            <div className="mx-5" key={item.id}>
              {renderItem(item)}
            </div>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <div
        className={`mx-auto my-0 bg-white rounded-lg text-center overflow-scroll max-h-[calc(100vh_-_74px)] scroll-smooth no-scrollbar md:max-h-[calc(100vh_-_154px)] relative md:top-1/2 md:left-0 md:right-0 md:max-w-[600px] border shadow-xl ${
          logEntry?.activityDialog.options ? "pb-16 md:pb-0" : ""
        }`}
      >
        <div className="sticky top-0 h-10 bg-primary flex flex-row justify-between items-center shdaow-2xl z-10">
          <div className={`text-lg ms-2 text-white visible`}>
            <FontAwesomeIcon
              icon={solid("arrow-left")}
              className={
                (LANGUAGES[logEntry?.prescriptionLanguage]?.dir === "rtl"
                  ? "rtl:rotate-180"
                  : "") +
                (logEntry?.selectedOptionId === "GOINGBACK"
                  ? " highlightedOption"
                  : "")
              }
            />
          </div>
          <h2 className="text-xl text-white text-bold">
            {logEntry?.activityDialog.label?.[logEntry?.prescriptionLanguage]}
            {/* {!logEntry ? <>NOTHING RECORDED</> : <></>} */}
          </h2>
          <div className="text-lg me-2 text-white"></div>
        </div>
        {renderActivityComponents()}
        <div className="fixed w-full md:sticky bottom-0 py-2 bg-secondary mt-5">
          {logEntry?.activityDialog.options?.map((option, optIdx) => (
            <Button
              className={
                "optionbuttons mb-1" +
                (logEntry?.selectedOptionId === option.value
                  ? " highlightedOption"
                  : "")
              }
              key={option.value}
            >
              {option.text[logEntry?.prescriptionLanguage]}
            </Button>
          ))}
        </div>
      </div>
      {/* <pre>{JSON.stringify(logEntry, null, 2)}</pre> */}
    </div>
  );
};

export default RenderReviewDialog;

import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";

function convertToSeconds(minutesSeconds) {
  let minutesAndSeconds = minutesSeconds.split(":");
  let result =
    parseInt(minutesAndSeconds[0]) * 60 + parseInt(minutesAndSeconds[1]);
  return result;
}

const Timer = ({ itemkey, content, onChange, defaultValue }) => {
  const { t } = useTranslation();

  const [timerState, setTimerState] = useState(null);
  const [seconds, setSeconds] = useState(0);

  const calculatedValue = useMemo(() => {
    let minutes = Math.floor(seconds / 60);
    let remainder = seconds - minutes * 60;
    return (
      String(minutes).padStart(2, "0") +
      ":" +
      String(remainder).padStart(2, "0")
    );
  }, [seconds]);

  useEffect(() => {
    if (defaultValue) {
      setSeconds(convertToSeconds(defaultValue));
      if (!!onChange) onChange(content.key, defaultValue);
    }
  }, [defaultValue]);

  const handleStartStopButton = () => {
    if (!!onChange) {
      if (timerState) {
        // timer is running, so stop it and send value
        window.clearInterval(timerState);
        setTimerState(null);
        onChange(content.key, calculatedValue);
      } else {
        // timer is stopped, start it
        setTimerState(
          window.setInterval(() => {
            setSeconds((oldSeconds) => oldSeconds + 1);
          }, 1000)
        );
      }
    }
  };

  const handleTimerReset = () => {
    if (!!onChange) {
      setSeconds(0);
      if (!timerState) {
        onChange(content.key, "00:00");
      }
    }
  };

  return (
    <div className="mt-5">
      <div
        className="timersize"
        style={{ color: timerState ? "blue" : "black" }}
      >
        {calculatedValue}
      </div>
      <Button
        className="me-2 bg-black hover:bg-slate-900"
        onClick={handleStartStopButton}
      >
        {t("start_stop")}
      </Button>
      <Button variant="outline" onClick={handleTimerReset}>
        {t("reset")}
      </Button>
    </div>
  );
};

export default Timer;

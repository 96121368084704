import React from "react";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../hooks/useAuth";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { toast } from "sonner";
import useInvitation from "../hooks/useInvitation";
import { INVITATIONS } from "../lib/keys";

const Accept = () => {
  const { auth } = useAuth();
  const { id } = useParams();
  const location = useLocation();
  const { getAllInvitations } = useInvitation();

  const { data: invitations } = useQuery({
    queryKey: [INVITATIONS],
    queryFn: getAllInvitations,
    enabled: !!auth.email,
  });

  if (!auth.email) {
    toast.info("Please login to accept invitation");
    return <Navigate to="/register" state={{ acceptId: id, from: location }} />;
  }

  return <div>{invitations?.map((invitation) => invitation._id)}</div>;
};

export default Accept;

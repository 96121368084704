import { useState, useEffect } from "react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";

const Checkboxes = ({ itemkey, content, onChange, defaultValue }) => {
  const [value, setValue] = useState({});

  const handleOptionValueChange = (optkey, val) => {
    if (onChange) {
      let newValue = { ...value };
      newValue[optkey] = val;

      setValue(newValue);
      onChange(content.key, newValue);
    } else {
      setValue(defaultValue);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
    if (onChange) onChange(content.key, defaultValue);
  }, [defaultValue]);

  return (
    <div className="text-start mt-5">
      <Label className="font-semibold">{content.label}</Label>
      {content.options.map((option) => (
        <div key={option.key} className="mt-2 flex items-center">
          <Checkbox
            id={option.key}
            checked={value?.[option.key] ? true : false}
            onCheckedChange={(value) =>
              handleOptionValueChange(option.key, value)
            }
          />
          <Label htmlFor={option.key} className="text-base font-normal mx-1.5">
            {option.label}
          </Label>
        </div>
      ))}
    </div>
  );
};

export default Checkboxes;

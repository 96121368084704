import { createContext, useContext, useState, useEffect } from "react";
import LoggerContext from "./LoggerProvider";
import useAuth from "../hooks/useAuth";
import useRetryFetch from "../hooks/useRetryFetch";
import { config } from "../Environment";
import { distributionService } from "../services/distributionService";

const PresListContext = createContext<any>({});

export const PresListProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { Logger } = useContext(LoggerContext);

  const { auth } = useAuth();
  const retryFetch = useRetryFetch();

  const [loadingPrescriptions, setLoadingPrescriptions] = useState(false);
  const [prescriptions, setPrescriptions] = useState(null);
  const [publicPrescriptions, setPublicPrescriptions] = useState(null);
  const [processes, setProcesses] = useState<any[]>([]);
  const [expertProcesses, setExpertProcesses] = useState<any[]>([]);
  const [userlist, setUserList] = useState<any[]>([]);

  const getPrescriptions = async (localInvalidate: boolean) => {
    Logger.debug("getPrescriptions has been called");
    setLoadingPrescriptions(true);
    if (auth.email) {
      let response = await retryFetch(
        `${config.API_BASE}/api/prescriptions/user/${auth.email}`
      );
      let presList = await response.json();
      setPrescriptions(presList);

      response = await retryFetch(
        `${config.API_BASE}/api/prescriptions/public`
      );
      let publicPresList = await response.json();

      setPublicPrescriptions(publicPresList);

      response = await retryFetch(
        `${config.API_BASE}/api/processes/email/${auth.email}`
      );
      let procList = await response.json();

      for (let process of procList) {
        if (
          userlist
            ?.map((user) => user.email)
            .indexOf(process.fiExpert?.email) !== -1
        )
          process.onlineState = userlist?.find(
            (user) => user.email === process.fiExpert?.email
          ).state;
        else process.onlineState = "offline";
      }

      setProcesses(procList);

      response = await retryFetch(
        `${config.API_BASE}/api/processes/expert/${auth.email}`
      );
      let expProcList = await response.json();

      for (let process of expProcList) {
        if (
          userlist
            ?.map((user) => user.email)
            .indexOf(process.fiInitiator?.email) !== -1
        )
          process.onlineState = userlist?.find(
            (user) => user.email === process.fiInitiator?.email
          ).state;
        else process.onlineState = "offline";
      }

      setExpertProcesses(expProcList);
    } else {
      let response = await retryFetch(
        `${config.API_BASE}/api/prescriptions/user/examples`
      );
      let presList = await response.json();
      setPrescriptions(presList);
    }
    setLoadingPrescriptions(false);
  };

  const updateProcesses = (procList: any[]) => {
    Logger.debug(procList);
    // update online state for experts and users in processes
    let newProcesses = [...procList];
    for (let process of newProcesses) {
      if (
        userlist.map((user) => user.email).indexOf(process.fiExpert?.email) !==
        -1
      )
        process.onlineState = userlist.find(
          (user) => user.email === process.fiExpert?.email
        ).state;
      else process.onlineState = "offline";
    }
    return newProcesses;
  };

  const updateExpertProcesses = (procList: any[]) => {
    Logger.debug(procList);
    // update online state for experts and users in processes
    let newProcesses = [...procList];
    for (let process of newProcesses) {
      if (
        userlist
          .map((user) => user.email)
          .indexOf(process.fiInitiator?.email) !== -1
      )
        process.onlineState = userlist.find(
          (user) => user.email === process.fiInitiator?.email
        ).state;
      else process.onlineState = "offline";
    }
    return newProcesses;
  };

  useEffect(() => {
    Logger.debug(
      "useEffect[userlist] has been called ... processes list length is " +
        processes?.length,
      userlist,
      loadingPrescriptions
    );
    if (!!processes && userlist && userlist.length && !loadingPrescriptions) {
      setProcesses(updateProcesses(processes));
      if (!!expertProcesses)
        setExpertProcesses(updateExpertProcesses(expertProcesses));
    }
  }, [userlist, loadingPrescriptions]);

  useEffect(() => {
    let userlistUnsubscribe = distributionService
      .getUserlist()
      .subscribe((users) => {
        Logger.debug("userlist received in prescriptionlist");
        setUserList(users);
      });

    return () => userlistUnsubscribe.unsubscribe();
  }, []);

  return (
    <PresListContext.Provider
      value={{
        refreshLists: getPrescriptions,
        userlist,
        prescriptions,
        publicPrescriptions,
        processes,
        expertProcesses,
        setPrescriptions,
        setPublicPrescriptions,
        setProcesses,
        setExpertProcesses,
      }}
    >
      {children}
    </PresListContext.Provider>
  );
};

export default PresListContext;

import convertPrescription from "./convert_prescription";

function validatePrescription(presJson) {
  let presObj = convertPrescription(presJson);

  // check that
  // 1. All nodes (activity steps) other than the start activity have incoming edges
  // 2. All nodes have at least one option
  // 3. At least one option of any node (activity step) has no outgoing edge (= open exit)

  let result = {
    nodesReachable: true,
    oneOptionPerNode: true,
    openExits: presObj.openExits.length > 0,
  };

  outerloopReachable: for (let node of presObj.nodes) {
    let nodeReachable = false;
    if (presObj.data[node.id].type === "start") {
      nodeReachable = true;
    } else {
      for (let edge of presObj.edges) {
        if (node.id === edge.target || presObj.data[node.id].type === "start") {
          nodeReachable = true;
          break;
        }
      }
    }
    if (!nodeReachable) {
      result.nodesReachable = false;
      break outerloopReachable;
    }
  }

  for (let node of presObj.nodes) {
    if (presObj.data[node.id].options.length === 0) {
      result.oneOptionPerNode = false;
      break;
    }
  }

  return result;
}

export default validatePrescription;

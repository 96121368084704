import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthProvider";
import { LoggerProvider } from "./context/LoggerProvider";
import "./index.css";
import "flag-icon-css/css/flag-icons.min.css";
import App from "./App";
import { Toaster } from "./components/ui/sonner";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["cookie", "navigator", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const suspenseMarkup = <span>Loading ...</span>;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={suspenseMarkup}>
    <LoggerProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </LoggerProvider>
    <Toaster expand={true} dir={i18n.dir} />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

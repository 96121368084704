import ReadOnlyView from "./ReadOnlyView";
import { MyContextProvider } from "./reactflow/MyContextProvider";
import { ReactFlowProvider } from "@xyflow/react";

export default function ReadOnlyViewWrapper({
  presHistoryId,
  highlightId,
  smaller,
}) {
  return (
    <MyContextProvider>
      <ReactFlowProvider>
        <ReadOnlyView
          presHistoryId={presHistoryId}
          highlightId={highlightId}
          smaller={smaller}
        />
      </ReactFlowProvider>
    </MyContextProvider>
  );
}

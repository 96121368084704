import { config } from "../Environment";
import useRetryFetch from "./useRetryFetch";
import endpoints from "../lib/endpoints";
import { Invitation } from "../lib/types";

const useInvitation = () => {
  const api = useRetryFetch();
  const getAllInvitations = async () => {
    const response = await api(`${config.API_BASE}${endpoints.invitations}`);
    return (await response.json()) as Invitation[];
  };

  return { getAllInvitations };
};

export default useInvitation;

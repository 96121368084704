import React, { createContext, useState, ReactNode } from "react";

interface AuthContextType {
  auth: {
    email: string | undefined;
    accessToken: string | undefined;
  };
  setAuth: React.Dispatch<
    React.SetStateAction<{
      email: string | undefined;
      accessToken: string | undefined;
    }>
  >;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState({
    email: localStorage.getItem("email") ?? undefined,
    accessToken: localStorage.getItem("accessToken") ?? undefined,
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

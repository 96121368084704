import { useState, useEffect } from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

const Textbox = ({ itemkey, content, onChange, defaultValue }) => {
  const [value, setValue] = useState("");

  const handleValueChange = (e) => {
    if (onChange) {
      setValue(e.target.value);
      onChange(content.key, e.target.value);
    } else {
      setValue(defaultValue || "");
    }
  };

  useEffect(() => {
    setValue(defaultValue);
    if (onChange) onChange(content.key, defaultValue);
  }, [defaultValue]);

  return (
    <div className="mt-5 text-start">
      <Label className="font-semibold">{content.label}</Label>
      <Input
        type="text"
        size="50"
        value={value}
        onChange={handleValueChange}
        className="block mt-2 w-full text-sm rounded-lg border focus:border-blue-500 bg-secondary border-gray-600 placeholder-gray-400 text-black focus:ring-blue outline-none"
      />
    </div>
  );
};

export default Textbox;

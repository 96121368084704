function convertPrescription(presJson) {
  // console.log("Loading/converting prescription...");
  let presObj = JSON.parse(presJson);

  if (presObj.version === 1) {
    // console.log("CONVERT:", presObj.cells.length, "Cells found");

    let activities = [];
    let links = [];
    for (let cell of presObj.cells) {
      if (cell.type === "Activity") {
        activities.push(cell);
      } else {
        links.push(cell);
      }
    }
    // console.log("CONVERT:", activities.length, "Activities found");
    // console.log("CONVERT:", links.length, "Links found");

    let nodes = [];
    let edges = [];
    let data = {};

    let portHandleMapping = {};

    for (let activity of activities) {
      let newNode = {
        id: activity.id,
        type: "activity",
        position: activity.position,
      };
      nodes.push(newNode);

      let outPortCount = 0;
      for (let port of activity.ports) {
        if (port.group === "out") {
          portHandleMapping[
            activity.id + "-" + port.id
          ] = `${activity.id}-${outPortCount}`;
          outPortCount++;
        }
      }

      let newData = {
        label: {
          en: activity.title,
        },
        type: "default",
        options: activity.options.map((oldOption, optIdx) => {
          return {
            text: {
              en: oldOption.text,
            },
            value: oldOption.id,
            handle: `${activity.id}-${optIdx}`,
          };
        }),
        custom: activity.data,
      };
      data[activity.id] = newData;
    }
    // console.log("CONVERT nodes:", nodes);
    // console.log("CONVERT data:", data);

    // console.log("CONVERT portHandleMapping", portHandleMapping);

    let reachedActivityIds = new Set();
    for (let link of links) {
      reachedActivityIds.add(link.target.activityId);
      let newEdge = {
        source: link.source.activityId,
        sourceHandle:
          portHandleMapping[
            link.source.activityId + "-" + link.source.portId
          ] || "not found " + link.source.portId,
        target: link.target.activityId,
        markerEnd: {
          type: "arrowclosed",
          color: "black",
          width: 20,
          height: 20,
        },
        type: "customedge",
        id: "reactflow__edge-" + Math.floor(Math.random() * 1000000),
      };
      edges.push(newEdge);
    }
    let reachedOnes = Array.from(reachedActivityIds);

    // determine start activity
    for (let node of nodes) {
      if (!reachedOnes.includes(node.id)) {
        data[node.id].type = "start";
      }
    }
    // console.log("CONVERT edges:", edges);

    let openExits = [];
    for (let node of nodes) {
      for (let idx = 0; idx < data[node.id].options.length; idx++) {
        if (!edges.find((edge) => edge.sourceHandle === `${node.id}-${idx}`)) {
          let newOpenExitHandle = {
            nodeLabel: data[node.id].label["en"],
            optionLabel: data[node.id].options[idx].text["en"],
            optionValue: data[node.id].options[idx].value,
            handleId: `${node.id}-${idx}`,
          };
          openExits.push(newOpenExitHandle);
        }
      }
    }

    return {
      prescriptionLanguage: "en",
      version: 2,
      nodes,
      edges,
      data,
      openExits,
    };
  } else {
    // console.log("Latest version 2 detected, nothing to convert.");
    return presObj;
  }
}

export default convertPrescription;

import { useState, useContext } from "react";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { distributionService } from "../services/distributionService";
//import useAuth from "../hooks/useAuth";
import useRetryFetch from "../hooks/useRetryFetch";
import { config } from "../Environment";

import "./Review.css";
import drawerIcon from "../images/drawer.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import ReadOnlyViewWrapper from "./ReadOnlyViewWrapper";
import { WSContext } from "../App";
import Chat from "./Chat";
import RenderReviewDialog from "./RenderReviewDialog";
import { Drawer } from "./ui/drawer";
import { directionService } from "../services/directionService";
import { LANGUAGES } from "../lib/utils";

import LoggerContext from "../context/LoggerProvider";

const Review = () => {
  // passed state
  const location = useLocation();
  const { processId, presName, expertEmail, userEmail, autochat } =
    location.state ?? {
      processId: "",
      presName: "",
      presHistoryId: "",
      expertEmail: "",
      autochat: "",
    };

  const [logEntries, setLogEntries] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [chatState, setChatState] = useState("nochat");

  const [designDirection, setDesignDirection] = useState(document.body.dir);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const retryFetch = useRetryFetch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { socket } = useContext(WSContext);

  const { Logger } = useContext(LoggerContext);

  // useEffect(() => {
  //   Logger.debug(
  //     "Review: state presHistoryId has been set to " + presHistoryId
  //   );
  //   setInternalPresHistoryId(presHistoryId);
  // }, [presHistoryId]);

  useEffectOnce(() => {
    Logger.debug(
      "Review has been called, and PresHistoryID in localStorage is " +
        sessionStorage.getItem("presHistoryID")
    );

    // if (!sessionStorage.getItem("presHistoryID") || !processId) {
    //   Logger.debug(
    //     "UserLog was refreshed in browser, presHistoryID is missing, redirecting to prescriptionlist"
    //   );
    //   navigate("/activities");
    // }
    const getLogEntries = async () => {
      let response = await retryFetch(
        `${config.API_BASE}/api/processlog/user/${processId}`
      );
      let entries = await response.json();

      setLogEntries(entries);
    };

    getLogEntries();

    let messageUnsubscribe = distributionService
      .getMessage()
      .subscribe((msg) => {
        Logger.debug("Review incoming message: ");
        Logger.debug(msg);

        switch (msg.command) {
          case "highlight":
            Logger.debug("Highlight received in REVIEW");

            setCurrentIndex(msg.payload);
            break;
          default:
            break;
        }
      });

    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        Logger.info("Direction change detected in Review");

        setDesignDirection(document.body.dir);
      });

    return () => {
      messageUnsubscribe.unsubscribe();
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  const back = () => {
    navigate("/activities");
  };

  const onstatechange = (data) => {
    setChatState(data);
  };

  return (
    <>
      {!expertEmail ? (
        <Drawer.Root open={drawerOpen} onOpenChange={setDrawerOpen}>
          <Drawer.Trigger>
            <div
              className={
                "drawer " +
                (designDirection === "rtl"
                  ? "rightsidedrawer rtl:rotate-180"
                  : "leftsidedrawer")
              }
            >
              <img src={drawerIcon} />
            </div>
          </Drawer.Trigger>
          <Drawer.Content
            className="content"
            origin={designDirection === "rtl" ? "right" : "left"} // Where the drawer opens from
            radius={10} // Border radius of the drawer
            size={"50%"} // Depth of the drawer
            visible={drawerOpen} // Required for animation
            style={{ paddingTop: "30px" }}
          >
            <Drawer.Title></Drawer.Title>
            <ReadOnlyViewWrapper
              presHistoryId={logEntries?.[currentIndex]?.fiPrescriptionHistory}
              highlightId={`${logEntries?.[currentIndex]?.activity}|${logEntries?.[currentIndex]?.selectedOptionId}`}
              smaller={true}
            />
          </Drawer.Content>
        </Drawer.Root>
      ) : (
        <></>
      )}
      <div className="columnflex">
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="reviewbox" style={{ flex: 1 }} onClick={back}>
              <FontAwesomeIcon
                icon={solid("arrow-left")}
                className={designDirection === "rtl" ? "rtl:rotate-180" : ""}
              />
              &nbsp;&nbsp; {t("back_to_overview")}
            </div>
            <h1
              style={{
                fontSize: "1.5em",
                color: "#4d7feb",
                display: "inline-block",
                marginLeft: "20px",
                flex: 2.5,
              }}
            >
              {presName}
            </h1>
            <div style={{ flex: 2 }}>
              <select
                className="stepselect"
                value={currentIndex}
                onChange={(e) => {
                  let newIdx = parseInt(e.target.value);
                  setCurrentIndex(newIdx);
                  if (chatState === "chatting") {
                    socket.send(
                      JSON.stringify({
                        command: "highlight",
                        payload: newIdx,
                      })
                    );
                  }
                }}
              >
                {logEntries.map((logEntry, idx) => (
                  <option key={idx} value={idx}>
                    {logEntry.parentProcessId ? <>&nbsp;&nbsp;&nbsp;</> : ""}
                    {
                      logEntry.activityDialog.label?.[
                        logEntry.prescriptionLanguage
                      ]
                    }
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="columnflex">
            <div
              style={{
                flex: 1,
                textAlign: designDirection === "rtl" ? "left" : "right",
                paddingTop: "60px",
              }}
            >
              <button
                onClick={() => {
                  setCurrentIndex((oldIdx) => oldIdx - 1);
                  if (chatState === "chatting") {
                    socket.send(
                      JSON.stringify({
                        command: "highlight",
                        payload: currentIndex - 1,
                      })
                    );
                  }
                }}
              >
                {currentIndex > 0 ? (
                  <FontAwesomeIcon
                    style={{ fontSize: "4em", color: "rgba(255, 144, 0, 0.6)" }}
                    className={
                      designDirection === "rtl" ? "rtl:rotate-180" : ""
                    }
                    icon={solid("chevron-left")}
                  />
                ) : (
                  <></>
                )}
              </button>
            </div>
            <div
              style={{ flex: 3 }}
              dir={
                LANGUAGES[logEntries[currentIndex]?.prescriptionLanguage]?.dir
                  ? LANGUAGES[logEntries[currentIndex]?.prescriptionLanguage]
                      ?.dir
                  : "ltr"
              }
            >
              <RenderReviewDialog logEntry={logEntries[currentIndex]} />
            </div>
            <div style={{ flex: 1, paddingTop: "60px" }}>
              <button
                onClick={() => {
                  Logger.debug(
                    "currentIndex when clicking right",
                    currentIndex
                  );
                  setCurrentIndex((oldIdx) => {
                    Logger.debug("oldIdx when clicking right", oldIdx);
                    return oldIdx + 1;
                  });
                  if (chatState === "chatting") {
                    socket.send(
                      JSON.stringify({
                        command: "highlight",
                        payload: currentIndex + 1,
                      })
                    );
                  }
                }}
              >
                {currentIndex < logEntries.length - 1 ? (
                  <FontAwesomeIcon
                    style={{ fontSize: "4em", color: "rgba(255, 144, 0, 0.6)" }}
                    className={
                      designDirection === "rtl" ? "rtl:rotate-180" : ""
                    }
                    icon={solid("chevron-right")}
                  />
                ) : (
                  <></>
                )}
              </button>
            </div>
          </div>
        </div>
        <div style={{ flex: 1, paddingTop: "60px" }}>
          <Chat
            otherEmail={expertEmail || userEmail}
            processId={processId}
            otherIsExpert={!!expertEmail}
            autochat={autochat}
            onStateChange={onstatechange}
          />
        </div>
      </div>
    </>
  );
};

export default Review;

const endpoints = {
  organizations: "/api/organizations",
  organizationsAddUser: "/api/organizations/addUser",
  organizationsRemoveUser: "/api/organizations/removeUser",
  organizationsRemoveUserAdmin: "/api/organizations/removeUserAsOrgAdmin",
  invitations: "/api/invitations",
  collections: "/api/collections",
  collectionsGetUsers: "/api/collections/getusers",
};

export default endpoints;
